.App {
  width:100%;
  font-family: 'Kosugi Maru', sans-serif;
}

a:link{text-decoration: none;}
a:hover{
  text-decoration: underline;
  color:#fff;
}
